<template>
  <HXContentCard title="Nueva Competencia" :toBack="{ name: 'Competencias' }">
    <form @submit.prevent="saveData">
      <div class="row p-4">
        <div class="col-xl-6">
          <div>
            <label for=" " class="form-label">Título Competencia</label>
            <input
              v-model="item.Titulo"
              placeholder="Escriba el Nombre de la Competencia..."
              type="text"
              class="form-control"
              required
              id=" "
            />
          </div>
        </div>
        <div class="col-xl-6">
          <div class="mb-3">
            <label for=" " class="form-label">Tipo de Competencia</label>
            <b-form-select
              v-model="item.TipoCompetenciaId"
              value-field="id"
              text-field="descripcion"
              :options="tipoCompetencia"
            >
              <template #first>
                <b-form-select-option :value="''" disabled>
                  -- Seleccione una opcion --
                </b-form-select-option>
              </template>
            </b-form-select>
          </div>
        </div>

        <div class="col-xl-12 mb-5">
          <label for=" " class="form-label">Descripción</label>
          <b-form-textarea
            v-model="item.Descripcion"
            id="textarea-default"
            placeholder="Escriba una descripción de la competencia..."
          ></b-form-textarea>
        </div>
      </div>
      <div class="text-right px-5 pb-4 d-flex justify-content-end">
        <vs-button icon animation-type="vertical" type="submit" class="mx-1">
          <i class="fas fa-save pr-1"></i>
          Guardar Cambios
          <template #animate>
            <i class="fas fa-save"></i>
          </template>
        </vs-button>
      </div>
    </form>
  </HXContentCard>
</template>

<script>
export default {
  name: "DataTable",
  data() {
    return {
      id: "",
      item: {
        Titulo: "",
        Descripcion: "",
        TipoCompetenciaId: "",
        EmpresaId: "",
        Estado: 0,
      },
      tipoCompetencia: [],
    };
  },
  async mounted() {
    this.$isLoading(true);
    this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
    //console.log("***************", this.usuario);
    //   await this.getData();
    //this.item.empresa.id = this.usuario.empresa.id;
    await this.getTipoCompetencias();
    this.$isLoading(false);
  },
  methods: {
    async getTipoCompetencias() {
      try {
        let res = await this.$store.dispatch("hl_post", {
          path: "TipoCompetencia/ListTipoCompetencia/",
        });
        this.tipoCompetencia = res;

        console.log("*****-- ", this.tipoCompetencia);
      } catch (error) {
        this.tipoCompetencia = {};
        console.log("err", error);
      }
    },
    //   async getData() {
    //     try {
    //       let res = await this.$store.dispatch("hl_get", {
    //         path: "Competencia/GetCompetencia/" + this.id,
    //       });
    //       this.item = res;

    //       console.log("*****-- ", this.item);
    //     } catch (error) {
    //       this.item = {};
    //       console.log("err", error);
    //     }
    //   },
    async saveData() {
      console.log("saving", this.item);
      try {
        this.item.EmpresaId = this.usuario.empresa.id;
        // this.item.municipio.departamentoPaisId = this.item.municipio.departamentoPais.id
        //  this.item.Estado = this.item.estado;
        // this.item.Titulo = this.item.titulo
        // this.item.Descripcion = this.item.descripcion
        let res = await this.$store.dispatch("hl_post", {
          path: "Competencia/Create/",
          data: this.item,
        });
        console.log("post empresas...", res);
        if (res) this.$router.push("/helex/competencias");
        else alert("Verificar");
      } catch (error) {
        //this.item = {};
        console.log("err", error);
      }
    },
  },
};
</script>
